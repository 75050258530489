<template>
  <div class="audience-location-map d-lg-flex flex-column min-height-100">
    <div class="text-h6 font-weight-bold mb-4">
      Location by City
    </div>

    <v-card class="border-radius-1em box-shadow-soft flex-grow-1">
      <v-card-text>
        <l-map
          :zoom="map.zoom"
          :center="map.center"
          @update:zoom="(val) => { map.zoom = val }"
          @update:center="(val) => { map.center = val }"
          :style="{ height: '330px' }"
        >
          <l-tile-layer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
          />

          <l-marker
            v-for="(item, index) in points"
            :key="index"
            :lat-lng="item"
          >
            <l-icon>
              <img
                width="16px"
                src="/img/marker.svg"
              />
            </l-icon>
          </l-marker>
        </l-map>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          text
          small
          color="primary"
          @click="shouldShowDialog = true"
        >
          View More
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="shouldShowDialog"
      width="600"
      scrollable
    >
      <v-card>
        <v-card-title>
          Location By City
        </v-card-title>

        <v-card-text class="make-it-scrollable">
          <v-data-table
            :headers="tableHeaders"
            :items="tableItems"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item.text="{ item }">
              <div class="d-flex align-center">
                <v-img
                  height="13"
                  max-width="20"
                  :src="proxyUrl(`https://flagcdn.com/w20/${item.countryCode.toLowerCase()}.png`, true)"
                ></v-img>

                <span class="ml-3">
                  {{ item.text }}
                </span>
              </div>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="shouldShowDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import node packages
import L from 'leaflet'
import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet'

// Configure the global leaflet object
L.Icon.Default.imagePath = 'img/leaflet/'

// Import leaflet's stylesheet
import 'leaflet/dist/leaflet.css'

// Export the SFC
export default {
  // Name of the component
  name: "AudienceLocationMap",

  // Accept incoming data from parent
  props: {
    allData: {
      type: Object,
      required: true
    },

    audienceData: {
      type: Object,
      required: true
    },

    totalValues: {
      type: Object,
      required: true
    },

    allTabs: {
      type: Array,
      required: true
    },

    selectedTab: {
      type: String,
      required: true
    }
  },

  // Register the components
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  },

  // Define local data variables
  data: () => ({
    shouldShowDialog: false,

    // Leaflet default configuration
    map: {
      zoom: 1,
      center: L.latLng(35.80354417328785, 11.39059066772461)
    },
  }),

  // Define readonly computable variables
  computed: {
    /**
     * Whether or not to show the location numerical values
     *
     * @returns {Boolean}
     */
    shouldHideLocationValueForTheUser() {
      return this.$store.getters["auth/profile"]?.email.includes("@x10.media")
    },

    /**
     * Get the points to be shown in the map
     *
     * @returns {Array}
     */
    points() {
      return this.audienceData.cityGeo.map((item) => L.latLng(item.coords.lat, item.coords.lon))
    },

    /**
     * Get the table headers
     *
     * @returns {Array}
     */
    tableHeaders() {
      return [
        {
          text: "Location",
          value: "text",
          sortable: true,
          visible: true
        },
        {
          text: "Followers",
          value: "followers",
          sortable: true,
          visible: !this.shouldHideLocationValueForTheUser
        },
        {
          text: "Likes",
          value: "likes",
          sortable: true,
          visible: !this.shouldHideLocationValueForTheUser
        },
      ].filter((item) => item.visible)
    },

    /**
     * Get the table row items
     *
     * @returns {Array}
     */
    tableItems() {
      const finalArray = []

      for (const tab of this.allTabs) {
        for (const iteratorItem of this.allData[tab]["city"]) {
          // check if an item with text is not already there, add a default one
          if (finalArray.findIndex((search) => search.text === iteratorItem.x) === -1) {
            finalArray.push({
              text: iteratorItem.x,
              countryCode: iteratorItem.countryCode,
              followers: "",
              likes: ""
            })
          }

          // now find the item and update it
          const foundItem = finalArray.find((search) => search.text === iteratorItem.x)

          foundItem[tab] = `${numeral.fNumber( this.totalValues[tab] * iteratorItem.y / 100 )}/${numeral.fPercent(iteratorItem.y)}`
        }
      }

      return finalArray
    },
  },
}
</script>
